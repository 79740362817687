import { BaseConfig, useConfig as hook } from '@eika-infrastruktur/config-provider';

import nbTranslations from './translations/nbTranslations-config';
import nnTranslations from './translations/nnTranslations-config';
import TranslationsConfigType from './translations/translationType';

interface Link {
    target?: string;
    text?: string;
    url: string;
}

// Used in legacy
export const defaultFormTexts = {
    header: 'Kontakt meg',
    ingressText:
        'Send inn skjemaet under så vil vi, innen utgangen av neste arbeidsdag, ta kontakt for en prat i banken eller per telefon.',
    name: 'Navn',
    email: 'E-post',
    phone: 'Telefon',
    send: 'Kontakt meg',
    gdprText:
        'Etter at vi har kontaktet deg blir informasjonen du oppgir her slettet etter gjeldende rutiner hos oss. Dersom du ønsker informasjon om dine rettigheter rundt blant annet sletting, innsyn, klage og samtykke finner du det på vår ',
    nameTooLong: 'Navnet er for langt.',
    fillName: 'Vennligst oppgi fullt navn.',
    fillContact: 'Vennligst oppgi kontaktinformasjon.',
    pendingText: 'Sender beskjed til rådgiver...',
    registerErrorHeader: 'Noe gikk galt.',
    registerErrorText: 'Prøv igjen senere.',
    takk: 'Takk!',
    buttonTextOk: 'Ok',
    registerSuccessHeader: 'Takk',
    registerSuccessText: 'Din henvendelse er mottatt, og du hører fra oss.',
};

const defaultTexts = {
    header: 'Ønsker du rådgivning?',
    knappText: 'Send',
    checkboxHeader: 'Hva gjelder det?',
    textAreaHeader: 'Beskriv kort hva det gjelder',
    ingressText: 'Bankens rådgivere er her for å hjelpe deg. Vi kontakter deg innen x virkedager.',
    pendingText: 'Sender beskjed til rådgiver...',
    registerErrorHeader: 'Noe gikk galt.',
    registerErrorText: 'Prøv igjen senere.',
    takk: 'Takk!',
    buttonTextOk: 'Ok',
    registerSuccessHeader: 'Takk',
    registerSuccessText: 'Din henvendelse er mottatt, og du hører fra oss innen X virkedager.',
    confirmTextHeader: 'Vennligst bekreft at du ønsker at rådgiver skal ringe deg.',
    confirmButtonText: 'Bekreft',
    cancleButtonText: 'Avbryt',
    subjectRequiredText: 'Vennligst besvar hva henvendelsen gjelder.',
    otherInformationText:
        'Ved innsending av dette skjemaet søker du om avdragsfrihet på alle dine boliglån. Du vil bli kontaktet av banken når saken er behandlet.',
    avdragsfrihet: {
        dropDownPretext: 'Søk om avdragsfrihet',
        dropDownLabel: 'Årsak til søknad om avdragsfrihet:',
        stepSelectLabel: 'Hvor lenge trenger du avdragsfrihet?',
        reasonRequiredText: 'Vennligst velg årsak.',
        textRequiredText: 'Vennligst beskrive hvorfor du har behov for avdragsfrihet.',
    },
    name: 'Navn',
    email: 'E-post',
    phone: 'Telefon',
    send: 'Kontakt meg',
    gdprText:
        'Etter at vi har kontaktet deg blir informasjonen du oppgir her slettet etter gjeldende rutiner hos oss. Dersom du ønsker informasjon om dine rettigheter rundt blant annet sletting, innsyn, klage og samtykke finner du det på vår ',
    nameTooLong: 'Navnet er for langt.',
    emailTooLong: 'Mailadressen er for lang.',
    phoneNumberTooLong: 'Telefonnummeret er for langt.',
    fillName: 'Vennligst oppgi fullt navn.',
    fillContact: 'Vennligst oppgi kontaktinformasjon.',
};

export interface Config extends BaseConfig {
    siteLang: 'nb' | 'nn';
    translations: {
        nb: TranslationsConfigType;
        nn: TranslationsConfigType;
    };
    bankregnr: string;
    overrideBnr: string | null;
    featureToggles: {
        showFeeWarning: boolean;
    };
    links: {
        redirectURL: Link;
        loanLink: Link;
        bannerLink: Link;
        contactLink: Link;
        gdprURL: Link;
        priceListLink?: Link;
    };
    subjects: Record<string, string>;
    avdragsfrihetSubjects: Record<string, string>;
    numberOfDays: string;
    numbers: {
        textAreaTextLength: number;
        freeTextMaxLength: number;
    };
    texts: typeof defaultTexts;
    meldinteresseconfig: MeldInteresseConfig;
}

type MeldInteresseConfig = {
    jseventname: string;
    produktkode: string;
    tags?: string;
    translations: {
        nb: MeldInteresseTextType;
        nn: MeldInteresseTextType;
    };
    kontakttlf: string;
    activatecheckbox: boolean;
    activateandhidecheckbox: boolean;
};

export type MeldInteresseTextType = {
    title: string;
    description: string;
    submitbuttonname: string;
};

export const defaultConfig: Config = {
    siteLang: 'nb' as const,
    translations: {
        nb: nbTranslations,
        nn: nnTranslations,
    },
    // Note: the optional bankregnr is a workaround for a bug!!! Removing it makes typescript scream in src/index.tsx
    bankregnr: '4605',
    overrideBnr: null,
    featureToggles: {
        showFeeWarning: true,
    },
    links: {
        redirectURL: {
            url: '/oversikt/kjop',
        },
        loanLink: { url: '/nettbank/laan' },
        bannerLink: {
            url: '/avdragsfrihet',
        },
        contactLink: {
            url: '/rådgivning',
        },
        gdprURL: {
            url: '/personvern-cookies',
            text: 'personvernside.',
        },
    },
    numberOfDays: '5 virkedager',
    subjects: {},
    avdragsfrihetSubjects: {},
    numbers: {
        textAreaTextLength: 50,
        freeTextMaxLength: 250,
    },
    texts: defaultTexts,
    meldinteresseconfig: {
        jseventname: 'sitecore-meld-interesse-js-event',
        produktkode: 'M16',
        translations: {
            nb: {
                title: 'Spør oss om pris på bilforsikring',
                description:
                    'Fyll ut opplysningene så kontakter vi deg for en uforpliktende prat og konkret tilbud på denne forsikringen.',
                submitbuttonname: 'Kontakt meg',
            },
            nn: {
                title: 'Spør oss om pris på bilforsikring',
                description:
                    'Fyll ut opplysningane så tek vi kontakt med deg for ein uforpliktande prat og konkret tilbod på denne forsikringa.',
                submitbuttonname: 'Kontakt meg',
            },
        },
        kontakttlf: '915 03 850',
        activatecheckbox: true,
        activateandhidecheckbox: false,
    },
};

export const defaultFormConfig: Config = {
    ...defaultConfig,
    texts: { ...defaultTexts, ...defaultFormTexts },
};

function createHook<T>(): () => T {
    return () => hook<T>();
}

export const useConfig = createHook<typeof defaultConfig>();
