import React, { useCallback, useState } from 'react';

import { CheckboxGroup } from '@eika/checkbox';
import { ExpandableInput } from '@eika/expandable-input';
import { Input, InputLabel } from '@eika/input';
import { ErrorLabel } from '@eika/label';
import { Link } from '@eika/link';

import { useConfig } from '../../default-config';
import useAcquaintanceForm from '../../hooks/useAcquaintanceForm';
import useDebounce from '../../hooks/useDebounce';
import useTranslation, { formatJsx } from '../../hooks/useTranslation';
import { BankUsage, bankUsage } from './bankUsage';
import AcquaintanceFormWrapper from './components/AcquaintanceFormWrapper';
import useValidate from './validate/useValidate';

import './AcquaintanceForm.scss';

const AcquaintanceForm: React.FC = () => {
    const translated = useTranslation();
    const { bankregnr, links, numbers } = useConfig();
    const acquaintanceForm = useAcquaintanceForm();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [bankUsageList, setBankUsageList] = useState<BankUsage[]>([]);
    const [reasonForNewBank, setReasonForNewBank] = useState('');
    const [importantBankMessage, setImportantBankMessage] = useState('');

    const [errors, isFormValid, resetErrorForKey] = useValidate(
        {
            name,
            email,
            phone,
            bankUsageList,
            reasonForNewBank,
            importantBankMessage,
        },
        translated.acquaintanceForm.errors.validation.bankUsageList,
    );

    const resetForm = useCallback(() => {
        resetErrorForKey('all');
        setName('');
        setEmail('');
        setPhone('');
        setBankUsageList([]);
        setReasonForNewBank('');
        setImportantBankMessage('');
        acquaintanceForm.reset();
    }, [
        acquaintanceForm,
        resetErrorForKey,
        setName,
        setEmail,
        setPhone,
        setBankUsageList,
        setReasonForNewBank,
        setImportantBankMessage,
    ]);

    const debouncedResetForm = useDebounce(resetForm, 3500);

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!isFormValid()) {
            return;
        }
        const nameSplit = name.split(' ');
        await acquaintanceForm.mutateAsync({
            bankregnr: { value: bankregnr },
            email,
            name: nameSplit[0] ?? '',
            phone,
            surname: nameSplit[nameSplit.length - 1] ?? '',
            reasonForNewBank,
            importantBankMessage,
            bankUsageList: bankUsageList.map((x) => translated.acquaintanceForm.bankUsages[x]),
            productForm: false,
        });
        debouncedResetForm();
    };

    const getCurrentBankUsageList = useCallback(
        (previousBankUsageList: BankUsage[], elementToAddOrRemove: BankUsage) => {
            const tempBankUsageArray = [...previousBankUsageList];
            if (tempBankUsageArray.includes(elementToAddOrRemove))
                return tempBankUsageArray.filter((x) => x !== elementToAddOrRemove);
            return [...tempBankUsageArray, elementToAddOrRemove];
        },
        [],
    );

    const toggleBankUsageItem = useCallback(
        (usage: BankUsage) => {
            setBankUsageList((prev) => getCurrentBankUsageList(prev, usage));
        },
        [setBankUsageList, getCurrentBankUsageList],
    );

    return (
        <AcquaintanceFormWrapper
            title={translated.acquaintanceForm.keepYouUpToDate}
            fieldTitle={translated.acquaintanceForm.fillOutForm}
            gdprMessage={formatJsx(
                translated.acquaintanceForm.gdprMessage,
                translated.acquaintanceForm.bankName,
                <Link linkType="inline" href={links.gdprURL.url}>
                    {translated.acquaintanceForm.moreAboutGdprHere}
                </Link>,
            )}
            onSubmit={onSubmit}
            isPending={acquaintanceForm.isPending}
            isSuccess={acquaintanceForm.isSuccess}
            className="acquaintance-form__wrapper"
        >
            <Input
                className="acquaintance-form__field acquaintance-form__field--name"
                value={name}
                onChange={(e) => {
                    setName(e.target.value);
                    resetErrorForKey('name');
                }}
                label={translated.commonForm.name}
                error={errors.name}
            />
            <Input
                className="acquaintance-form__field acquaintance-form__field--email"
                value={email}
                onChange={(e) => {
                    setEmail(e.target.value);
                    resetErrorForKey('email');
                }}
                label={translated.commonForm.email}
                error={errors.email}
            />
            <Input
                className="acquaintance-form__field acquaintance-form__field--phone"
                value={phone}
                onChange={(e) => {
                    setPhone(e.target.value);
                    resetErrorForKey('phone');
                }}
                label={translated.commonForm.phone}
                error={errors.phone}
            />

            <InputLabel
                label={translated.acquaintanceForm.speciallyImportantToYouAboutNewBank}
                className="acquaintance-form__field acquaintance-form__field--bank-important"
            >
                <ExpandableInput
                    value={importantBankMessage}
                    onChange={(e) => setImportantBankMessage(e.target.value)}
                    maxLength={numbers.freeTextMaxLength}
                />
            </InputLabel>
            <InputLabel
                label={translated.acquaintanceForm.reasonForNewBank}
                className="acquaintance-form__field acquaintance-form__field--reason"
            >
                <ExpandableInput
                    value={reasonForNewBank}
                    onChange={(e) => setReasonForNewBank(e.target.value)}
                    maxLength={numbers.freeTextMaxLength}
                />
            </InputLabel>

            <section className="acquaintance-form__field acquaintance-form__field--bank-usage">
                <CheckboxGroup
                    label={translated.acquaintanceForm.bankUsage}
                    options={bankUsage.map((usage) => ({
                        label: translated.acquaintanceForm.bankUsages[usage],
                        value: usage,
                        checked: bankUsageList.includes(usage),
                        onChange: () => toggleBankUsageItem(usage),
                    }))}
                />
                {errors.bankUsageList && (
                    <ErrorLabel className="acquaintance-field__bank-usage-error-message" text={errors.bankUsageList} />
                )}
            </section>
        </AcquaintanceFormWrapper>
    );
};
export default AcquaintanceForm;
