export const defaultProductList = [
    'Rammelån',
    'Lån til fritidsbolig',
    'Finansieringsbevis',
    'Billån',
    'Kredittkort',
    'Kun digitalt kort',
    'Forsikringer',
    'Fond',
    'Barnekonto',
    'Barnekort',
    'BSU',
    'Depositumskonto',
    'Refinansiering av forbrukslån',
    'Annet',
];
