import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import classNames from 'classnames';
import React from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import registerLogger, { error as logError, ErrorBoundary } from '@eika-infrastruktur/log';
import { loadContainers } from '@eika-infrastruktur/render';

import { CONTEXT_PATH } from './actions';
import { AcquaintanceForm } from './components/acquaintance-form';
import AcquaintanceProductForm from './components/acquaintance-form/AcquaintanceProductForm';
import { ContactMeForm } from './components/contact-me-form';
import AdvisorContactBanner from './containers/advisor-contact-banner';
import AdvisorContactForm from './containers/advisor-contact-form';
import AvdragsfrihetApp from './containers/avdragsfrihet-app';
import AvdragsfrihetBanner from './containers/avdragsfrihet-banner';
import MeldInteresseApp from './containers/meld-interesse-app';
import { defaultConfig } from './default-config';
import getCssVariableValue from './utils/getCssVariableValue';

import './styles/main.scss';

// eslint-disable-next-line import/prefer-default-export
export const globalCssNamespace = 'no-eika-kontakt-meg-web';

const staleTime = 60 * 60 * 15; // 15 min
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime,
            retry: 1,
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
            retryOnMount: false,
        },
    },
});

const restBaseUrl = `${CONTEXT_PATH}/rest/resource/log`;
registerLogger(restBaseUrl);

const renderApp = (
    MyApp: React.ComponentType,
    namespace: string,
    withPadding: boolean = true,
    appDefaultConfig: Record<string, unknown> = defaultConfig,
): void => {
    loadContainers(
        namespace,
        () => (
            <ErrorBoundary
                onError={(error, errorInfo) =>
                    logError({
                        message: error.message,
                        errorName: error.name,
                        stack: error.stack,
                        errorInfo,
                        url: window.location.href,
                        namespace,
                    })
                }
            >
                <React.StrictMode>
                    <QueryClientProvider client={queryClient}>
                        <SkeletonTheme
                            baseColor={getCssVariableValue('--neutral-background')}
                            highlightColor={getCssVariableValue('--card-background-light')}
                        >
                            <div className={classNames(globalCssNamespace, { 'sgw-content': withPadding })}>
                                <MyApp />
                            </div>
                        </SkeletonTheme>
                    </QueryClientProvider>
                </React.StrictMode>
            </ErrorBoundary>
        ),
        appDefaultConfig,
    );
};

renderApp(AvdragsfrihetApp, 'dagligbank-avdragsfrihet-web');
renderApp(AvdragsfrihetBanner, 'dagligbank-avdragsfrihet-web-banner');
renderApp(AdvisorContactForm, 'dagligbank-kontakt-meg-web-advisor-form');
renderApp(AdvisorContactBanner, 'dagligbank-kontakt-meg-web-advisor-banner');
renderApp(MeldInteresseApp, 'dagligbank-meld-interesse-web');
renderApp(() => <ContactMeForm type="private" />, 'dagligbank-contact-form');
renderApp(() => <ContactMeForm type="business" />, 'dagligbank-contact-form-business');
renderApp(AcquaintanceForm, 'dagligbank-acquaintance-form', false);
renderApp(AcquaintanceProductForm, 'dagligbank-acquaintance-product-form', false);
