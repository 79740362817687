import classNames from 'classnames';
import React from 'react';

import { ButtonWithSpinner } from '@eika/button-with-spinner';

import useTranslation from '../../../hooks/useTranslation';
import { acquaintanceFormTrackingID } from '../../../utils/gtm/trackIDs/acquaintance';

type Props = {
    title: string;
    fieldTitle: string;
    gdprMessage: React.ReactNode;
    isPending: boolean;
    isSuccess: boolean;
} & React.PropsWithoutRef<React.JSX.IntrinsicElements['form']>;

const AcquaintanceFormWrapper: React.FC<Props> = ({
    className,
    title,
    fieldTitle,
    children,
    gdprMessage,
    isSuccess,
    isPending,
    ...rest
}) => {
    const { contactForm } = useTranslation();
    return (
        <form className={classNames('acquaintance-form__wrapper', className)} {...rest}>
            <div className="acquaintance-form__intro-wrapper">
                <h2 className="acquaintance-form__intro--title">{title}</h2>
            </div>
            <section className={classNames('acquaintance-form__field-wrapper', 'acquaintance-form__info-wrapper')}>
                <h3 className="acquaintance-form__field-wrapper--title">{fieldTitle}</h3>
                {children}
            </section>
            <section className="acquaintance-form__submit-wrapper">
                <p className="acquaintance-form__gdpr-description">{gdprMessage}</p>
                <div className="acquaintance-form__submit-button--wrapper">
                    <ButtonWithSpinner
                        className="acquaintance-form__submit--button"
                        buttonType="positive"
                        type="submit"
                        showSpinner={isPending}
                        showSuccess={isSuccess}
                        data-track-id={acquaintanceFormTrackingID}
                        data-track-linkname="submit"
                    >
                        {contactForm.sendForm}
                    </ButtonWithSpinner>
                    {isSuccess && (
                        <span className="acquaintance-form__submit--success-message">{contactForm.successMessage}</span>
                    )}
                </div>
            </section>
        </form>
    );
};

export default AcquaintanceFormWrapper;
