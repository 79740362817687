import TranslationsConfigType from './translationType';

const nbTranslations: TranslationsConfigType = {
    tryAgain: 'Prøv igjen',
    send: 'Send',
    buttonTextOk: 'Ok',
    month: 'måned',
    months: 'måneder',
    avdragsfrihet: {
        title: 'Vil du søke om avdragsfrihet?',
        description:
            'Med avdragsfrihet betaler du kun renter og omkostninger på lånet i en periode. Det betyr at lånet ikke minsker, siden du utsetter selve nedbetalingen av lånet, men at du får lavere månedlige kostnader i en kortere periode.',
        feeWarning: 'Vær oppmerksom på at det kan tilkomme gebyr ved avdragsfrihet.',
        feeWarningLinkText: 'Se mer i vår prisliste',
        promptDescription:
            'Hvis du har du mistet inntekten eller har blitt permittert kan du få midlertidig avdragsfrihet. Send oss en forespørsel så skal vi se på saken!',
        banner: {
            title: 'Søk om avdragsfrihet på boliglånet ditt',
            description: 'Er du permitert eller har du mistet inntekten? Da kan du søke om avdragsfrihet.',
            shortDescription: 'Er du permitert eller har du mistet inntekten?',
            shortTitle: 'Avdragsfrihet på lån',
            button: 'Søk om avdragsfrihet',
        },
        form: {
            start: 'Kom i gang',
            previous: 'Forrige',
            next: 'Neste',
            loanTitle: 'Hvilket lån ønsker du avdragsfrihet på?',
            durationTitle: 'Hvor lenge trenger du avdragsfrihet?',
            durationShowMoreOptions: 'Se flere tidsrom',
            durationHideMoreOptions: 'Vis færre tidsrom',
            reasonTitle: 'Hva er grunnen til at du vil søke om avdragsfrihet',
            successTitle: 'Søknaden er sendt',
            successDescription: 'Du hører fra oss når saken er ferdig behandlet.',
            reasonExplainedLabel: 'Beskriv kort hva det gjelder: ',
            reasonExplainedErrorTooLong: 'Beskrivelsen din er for lang. Du kan ikke ha mer enn {} tegn.',
            reasonExplainedErrorTooShort: 'Denne beskrivelsen er for kort',
            creditCheck: 'Det vil bli foretatt en kredittvurdering av deg når du sender inn søknaden',
            chooseAccountError: 'Du må velge lån før du kan gå videre',
            chooseDurationError: 'Du må velge tidsrom før du kan gå videre',
            chooseReasonError: 'Du må velge grunn før du kan gå videre',
        },
        noLoansHeader: 'Du har ingen lån hos oss',
        noLoansText:
            'For å kunne søke om avdragsfrihet må du ha lån hos oss. Du kan søke om boliglån, smålån, billån og lån til andre kjøretøy på lånesidene våre.',
        noLoansRedirect: 'Gå til lån',
        loanError: 'Det skjedde noe galt ved henting av lånene dine, så vi viser bare noen av de',
    },
    meldInteresse: {
        contact: {
            privacyText:
                'Etter at vi har kontaktet deg blir informasjonen du oppgir her slettet etter gjeldende rutiner hos oss. Mer om ',
            linkText: 'hvordan vi håndterer personvern.',
            button: 'Kontakt meg',
        },
        footerText: 'Du kan også ringe oss på',
        form: {
            onskerTilbud: 'Ønsker tilbud på andre forsikringer',
            other: 'Hvilke forsikringer trenger du?',
            address: 'Adresse',
            postalcode: 'Postnummer og -sted',
            errors: {
                required: {
                    address: 'Du må legge inn adresse.',
                    postalcode: 'Du må legge inn postnummeret og poststed.',
                },
            },
        },
        confirmation: {
            headerWithCustomerFirstName: 'Takk, {}!',
            informationWithCustomerPhoneNumber: 'Vi ringer deg på {} så snart en av våre rådgivere er ledige.',
            button: 'Ferdig',
        },
    },
    contact: {
        title: 'Ønsker du rådgivning?',
        description:
            'Ønsker du rådgivning innenfor bankens tjenester kan du få det kostnadsfritt hos oss. Send forespørsel og vi kontakter deg!',
        buttonText: 'Send forespørsel',
        requestSentTitle: 'Forespørsel er sendt',
        requestSentDescription: 'Du hører fra oss innen {}',
    },
    bedrift: {
        title: 'Få bedre oversikt med bedriftsmobilbank!',
        description:
            'Hvis du har en konto som du bruker til en bedrift eller en forening kan  bedriftsmobilbank være et godt alternativ. Send forespørsel og vi kontakter deg!',
    },
    errors: {
        somethingWentWrong: 'Noe gikk galt!',
        somethingWentWrongFetchingReceivers: 'Noe gikk galt ved henting av mottakerne dine!',
        somethingWentWrongDeletingReceiver: 'Beklager, vi klarte ikke å slette mottakeren',
        somethingWentWrongChangingReceiver: 'Beklager, vi klarte ikke å endre mottakeren',
        ohNo: 'Å nei!',
        oops: 'Oj sann!',
        timeoutMessage: 'Vi beklager, det tok litt lenger tid enn vi hadde tenkt. Du må gjerne prøve igjen',
        defaultMessage: 'Vi beklager, det skjedde en teknisk feil. Vennligst prøv igjen litt senere.',
        badRequestMessage:
            'Det skjedde en feil ved innsending av skjema. Er du sikker på at du har fylt ut skjemaet riktig?',
    },
    createRequest: 'Kom i gang',
    copyErrorCode: 'Kopier feilmeldingskode',
    commonForm: {
        name: 'Navn',
        firstName: 'Fornavn',
        surname: 'Etternavn',
        phone: 'Telefonnummer',
        email: 'E-postadresse',
        errors: {
            required: {
                name: 'Du må legge in navn',
                firstName: 'Du må legge inn fornavn.',
                surname: 'Du må legge inn etternavn.',
                phone: 'Du må legge inn telefonnummer.',
                email: 'Du må legge inn e-postadresse.',
            },
            validation: {
                firstName: 'Fornavnet kan bare være {} tegn langt.',
                surname: 'Etternavnet kan bare være {} tegn langt',
                phone: 'Telefonnummeret er ikke gyldig.',
                email: 'E-postadressen er ikke gyldig.',
                owntext:
                    'Du må skrive en kort beskrivelse av hva du ønsker å snakke med oss om for å kunne sende inn skjeamet',
                emailAndPhone:
                    'Du må skrive inn telefonnummeret og/eller e-postadressen din for å kunne sende inn skjemaet',
            },
        },
    },
    contactForm: {
        contactUs: 'Kontakt oss',
        businessName: 'Bedriftsnavn',
        orgNr: 'Organisasjonsnummer',
        businessInfo: 'Firmaopplysninger',
        contactPerson: 'Kontaktperson',
        purposeOfContact: 'Hva ønsker du å snakke med oss om?',
        purposeOfContactInfoMessage:
            'Av hensyn til ditt personvern ber vi deg om å ikke legge inn opplysninger som fødselsnummer eller annen sensitiv informasjon i meldingen.',
        contactDeletionRoutines:
            'Etter at vi har kontaktet deg blir informasjonen du oppgir her slettet etter gjeldende rutiner hos oss. Mer om {}',
        contactDeletionGDPRLinkText: 'hvordan vi håndterer personvern',
        sendForm: 'Send skjema',
        successMessage: 'Skjema er mottatt, vi kontakter deg så raskt vi kan',
        errors: {
            required: { businessName: 'Du må legge inn bedriftsnavn på {} eller flere tegn' },
        },
    },
    acquaintanceForm: {
        keepYouUpToDate: 'Vil du vi skal holde deg oppdatert?',
        fillOutForm: 'Da er det bare å fylle inn litt informasjon så hører du plutselig fra oss!',
        fillOutProductForm:
            'Da er det bare å fylle inn litt informasjon og velg produkter du er interessert i så hører du fra oss!',
        speciallyImportantToYouAboutNewBank: 'Hva er spesielt viktig for deg når du skal velge ny bank?',
        reasonForNewBank: 'Hva er grunnen til at du er på utkikk etter ny bank?',
        bankUsage: 'Hva bruker du banken din til i dag?',
        bankUsages: {
            BILLS: 'Jeg betaler regninger og bruker kortet mitt',
            'MOBILE-PAY': 'Jeg betaler med mobilen',
            'FAMILY-EXPENSES': 'Jeg har felleskonto for familiens utgifter',
            'FAMILY-ACCOUNT-OVERVIEW': 'Jeg har oversikt over kontoene til familien min',
            'SAVINGS-ACCOUNT': 'Jeg har sparekonto',
            'HOUSE-LOAN': 'Jeg har boliglån',
            'CAR-LOAN': 'Jeg har billån',
            'OTHER-LOANS': 'Jeg har andre lån',
            'CREDIT-CARD': 'Jeg har kredittkort i banken min',
            'SHARES-AND-FUNDS': 'Jeg sparer i aksjer og fond',
            ADVISOR: 'Jeg bruker banken som rådgiver',
            INSURANCE: 'Jeg har forsikring i banken',
        },
        prodcutUsage: 'Hvilke produkt er du interessert i?',
        gdprMessage:
            'Så hyggelig at du vil vite mer om oss! Når du sender inn dette skjemaet gir du oss samtykke til å behandle opplysningene dine. Vi vil kun bruke kontaktinformasjonen din til å holde deg oppdatert om hva som skjer i {}. {}',
        bankName: 'Penni',
        moreAboutGdprHere: 'Les mer om hvordan vi håndterer personvern her.',
        otherProduct: 'Beskriv et annet produkt her',
        errors: {
            validation: {
                bankUsageList: 'Du må velge en ting du bruker banken til!',
                productList: 'Du må velge en et produkt i listen over!',
            },
        },
    },
};

export default nbTranslations;
