import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';

import { stringValueCheck } from '@eika-infrastruktur/format';
import { isEmpty } from '@eika-infrastruktur/format/src/utils';

import { format } from '../hooks/useTranslation';
import TranslationsConfigType from '../translations/translationType';
import { getNames } from './contact-form-utils';

export const isValidEmail = (value?: string, enabled = true) => {
    if (!enabled) return true;
    if (!value || value.length === 0) {
        return false;
    }

    return isEmail(value);
};

export const isValidPhoneNumber = (value?: string, enabled = true) => {
    if (!enabled) return true;
    if (!value || value.length === 0) {
        return false;
    }

    return isMobilePhone(value);
};
export const isValidBusinessName = (value: string | undefined, minLength: number): boolean => {
    return !isEmpty(value) && !!value && value.length >= minLength;
};

export const isValidOwnText = (value: string | undefined, minLength: number): boolean => {
    return !isEmpty(value) && !!value && value.length >= minLength;
};

export const validateName =
    (translated: TranslationsConfigType) =>
    (value: string | undefined, maxLength: number): string | undefined => {
        if (!stringValueCheck(value) || !value) return translated.commonForm.errors.required.name;
        const [firstName, surname] = getNames(value);
        if (firstName.length === 0) return translated.commonForm.errors.required.firstName;
        if (surname.length === 0) return translated.commonForm.errors.required.surname;
        if (firstName.length > maxLength / 2)
            return format(translated.commonForm.errors.validation.firstName, maxLength / 2);
        if (surname.length > maxLength / 2)
            return format(translated.commonForm.errors.validation.surname, maxLength / 2);
        return undefined;
    };
