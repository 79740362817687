import React, { useCallback, useState } from 'react';

import { CheckboxGroup } from '@eika/checkbox';
import { Input } from '@eika/input';
import { ErrorLabel } from '@eika/label';
import { Link } from '@eika/link';

import { useConfig } from '../../default-config';
import useAcquaintanceForm from '../../hooks/useAcquaintanceForm';
import useDebounce from '../../hooks/useDebounce';
import useTranslation, { formatJsx } from '../../hooks/useTranslation';
import AcquaintanceFormWrapper from './components/AcquaintanceFormWrapper';
import { defaultProductList } from './productList';
import useValidate from './validate/useValidate';

import './AcquaintanceForm.scss';

const AcquaintanceProductForm: React.FC = () => {
    const translated = useTranslation();
    const { bankregnr, links, numbers } = useConfig();
    const acquaintanceForm = useAcquaintanceForm();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [otherProduct, setOtherProduct] = useState('');

    const [productList, setProductList] = useState<string[]>([]);

    const [errors, isFormValid, resetErrorForKey] = useValidate(
        {
            name,
            email,
            phone,
            bankUsageList: productList,
        },
        translated.acquaintanceForm.errors.validation.productList,
    );

    const resetForm = useCallback(() => {
        resetErrorForKey('all');
        setName('');
        setEmail('');
        setPhone('');
        setOtherProduct('');
        setProductList([]);
        acquaintanceForm.reset();
    }, [acquaintanceForm, resetErrorForKey, setName, setEmail, setPhone, setProductList]);

    const debouncedResetForm = useDebounce(resetForm, 3500);

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!isFormValid()) {
            return;
        }
        const nameSplit = name.split(' ');
        await acquaintanceForm.mutateAsync({
            bankregnr: { value: bankregnr },
            email,
            name: nameSplit[0] ?? '',
            phone,
            surname: nameSplit[nameSplit.length - 1] ?? '',
            bankUsageList: [
                ...productList.filter((product) => product.toLowerCase() !== 'annet'),
                otherProduct ? `Annet: ${otherProduct}` : '',
            ].filter(Boolean),
            productForm: true,
        });
        debouncedResetForm();
    };

    const getCurrentBankUsageList = useCallback((previousBankUsageList: string[], elementToAddOrRemove: string) => {
        const tempBankUsageArray = [...previousBankUsageList];
        if (tempBankUsageArray.includes(elementToAddOrRemove))
            return tempBankUsageArray.filter((x) => x !== elementToAddOrRemove);
        return [...tempBankUsageArray, elementToAddOrRemove];
    }, []);

    const toggleBankUsageItem = useCallback(
        (usage: string) => {
            setProductList((prev) => getCurrentBankUsageList(prev, usage));
        },
        [setProductList, getCurrentBankUsageList],
    );

    return (
        <AcquaintanceFormWrapper
            title={translated.acquaintanceForm.keepYouUpToDate}
            fieldTitle={translated.acquaintanceForm.fillOutProductForm}
            gdprMessage={formatJsx(
                translated.acquaintanceForm.gdprMessage,
                translated.acquaintanceForm.bankName,
                <Link linkType="inline" href={links.gdprURL.url}>
                    {translated.acquaintanceForm.moreAboutGdprHere}
                </Link>,
            )}
            onSubmit={onSubmit}
            isPending={acquaintanceForm.isPending}
            isSuccess={acquaintanceForm.isSuccess}
            className="acquaintance-form__wrapper"
        >
            <Input
                className="acquaintance-form__field acquaintance-form__field--name"
                value={name}
                onChange={(e) => {
                    setName(e.target.value);
                    resetErrorForKey('name');
                }}
                label={translated.commonForm.name}
                error={errors.name}
            />
            <Input
                className="acquaintance-form__field acquaintance-form__field--email"
                value={email}
                onChange={(e) => {
                    setEmail(e.target.value);
                    resetErrorForKey('email');
                }}
                label={translated.commonForm.email}
                error={errors.email}
            />
            <Input
                className="acquaintance-form__field acquaintance-form__field--phone"
                value={phone}
                onChange={(e) => {
                    setPhone(e.target.value);
                    resetErrorForKey('phone');
                }}
                label={translated.commonForm.phone}
                error={errors.phone}
            />

            <section className="acquaintance-form__field acquaintance-form__field--bank-usage">
                <CheckboxGroup
                    label={translated.acquaintanceForm.prodcutUsage}
                    options={defaultProductList.map((usage) => ({
                        id: usage.toLowerCase(),
                        label: usage,
                        value: usage,
                        checked: productList.includes(usage),
                        onChange: () => toggleBankUsageItem(usage),
                    }))}
                />
                {productList.some((product) => product.toLowerCase() === 'annet') && (
                    <Input
                        aria-label="annet"
                        className="acquaintance-form__field acquaintance-form__field--other-product"
                        value={otherProduct}
                        placeholder={translated.acquaintanceForm.otherProduct}
                        onChange={(e) => setOtherProduct(e.target.value)}
                        maxLength={numbers.textAreaTextLength}
                    />
                )}
                {errors.bankUsageList && (
                    <ErrorLabel className="acquaintance-field__bank-usage-error-message" text={errors.bankUsageList} />
                )}
            </section>
        </AcquaintanceFormWrapper>
    );
};
export default AcquaintanceProductForm;
