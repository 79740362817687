import { useCallback, useState } from 'react';

import useTranslation, { format } from '../../../hooks/useTranslation';
import {
    isValidBusinessName,
    isValidEmail,
    validateName,
    isValidPhoneNumber,
    isValidOwnText,
} from '../../../utils/validateContact';
import { ContactFormType, ErrorTypes, FormValues } from '../types';

const BUSINESS_MIN_LENGTH = 3;
const NAME_MAX_LENGTH = 50;
const OWN_TEXT_MIN_LENGTH = 3;

const initialErrorState: ErrorTypes = {
    name: undefined,
    businessName: undefined,
    email: undefined,
    phone: undefined,
};

const useValidate = (
    formValues: FormValues,
    type: ContactFormType,
): [ErrorTypes, () => boolean, (key: keyof ErrorTypes | 'all') => void] => {
    const translated = useTranslation();
    const [errors, setErrors] = useState<ErrorTypes>(initialErrorState);

    const resetErrorForKey = useCallback(
        (key: keyof ErrorTypes | 'all') => {
            if (key === 'all') {
                setErrors(initialErrorState);
                return;
            }
            const errorsToSet = errors;

            if (!errorsToSet[key]) return;
            errorsToSet[key] = undefined;
            setErrors(errorsToSet);
        },
        [errors, setErrors],
    );

    const validateCallback = useCallback((): boolean => {
        const errorsToSet = {
            ...initialErrorState,
            name: validateName(translated)(formValues.name, NAME_MAX_LENGTH),
        };
        const emptyPhone = !formValues.phone || formValues.phone === '';
        const emptyEmail = !formValues.email || formValues.email === '';

        if (emptyEmail && emptyPhone) {
            errorsToSet.emailAndPhone = translated.commonForm.errors.validation.emailAndPhone;
        }

        if (!isValidEmail(formValues.email) && !emptyEmail) {
            errorsToSet.email = translated.commonForm.errors.validation.email;
        }
        if (!isValidPhoneNumber(formValues.phone) && !emptyPhone) {
            errorsToSet.phone = translated.commonForm.errors.validation.phone;
        }
        if (!isValidBusinessName(formValues.businessName, BUSINESS_MIN_LENGTH) && type === 'business') {
            errorsToSet.businessName = format(translated.contactForm.errors.required.businessName, BUSINESS_MIN_LENGTH);
        }
        if (!isValidOwnText(formValues.ownText, OWN_TEXT_MIN_LENGTH)) {
            errorsToSet.ownText = translated.commonForm.errors.validation.owntext;
        }
        setErrors({ ...errorsToSet });
        return !Object.entries(errorsToSet).some(([, value]) => value);
    }, [formValues, type, translated, setErrors]);

    return [errors, validateCallback, resetErrorForKey];
};

export default useValidate;
